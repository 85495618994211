<template>
  <div class="prayer-outer-wrapper">
    <div class="prayer">
      <h2 class="title-underline title-offset animation-underline">
        {{ cms.page.fgTheImportanceOf }} <strong>{{ cms.page.fgPrayer }}</strong>
      </h2>
      <div class="importance">
        <p>
          {{ cms.page.fgPrayerIsEssential }}
        </p>
        <ul class="list">
          <li class="list-item">
            <p class="list-item-title">
              {{ cms.page.fgContinueSteadfastly }}
            </p>
            <p class="list-item-text">
              {{ cms.page.fgColossiansBiblePassage }}
            </p>
          </li>
          <li class="list-item">
            <p class="list-item-title">{{ cms.page.fgPrayWithoutceasing }}</p>
            <p class="list-item-text">
              {{ cms.page.fg1ThessaloniansBiblePassage }}
            </p>
          </li>
        </ul>
        <p>
          {{ cms.page.fgWhenYouPrayForGideon }}
        </p>
      </div>
      <div class="approach">
        <h3 class="subtitle">
          {{ cms.page.fgApprochToPrayer }}
        </h3>
        <p>
          {{ cms.page.fgPerhapsYourAreANewBeliver }}
        </p>

        <table>
          <tr>
            <th>{{ cms.page.fgAcronym }}</th>
            <th>{{ cms.page.fgStandsFor }}</th>
            <th>{{ cms.page.fgDescription }}</th>
          </tr>
          <tr>
            <td>{{ cms.page.fgP }}</td>
            <td>{{ cms.page.fgPraise }}</td>
            <td>{{ cms.page.fgAcknowledgeGod }}</td>
          </tr>
          <tr>
            <td>{{ cms.page.fgR }}</td>
            <td>{{ cms.page.fgRepent }}</td>
            <td>{{ cms.page.fgConfessAnyUnresolved }}</td>
          </tr>
          <tr>
            <td>{{ cms.page.fgA }}</td>
            <td>{{ cms.page.fgAsk }}</td>
            <td>{{ cms.page.fgAskForThoseThingsOnly }}</td>
          </tr>
          <tr>
            <td>{{ cms.page.fgY }}</td>
            <td>{{ cms.page.fgYield }}</td>
            <td>{{ cms.page.fgCommitYourWillToGod }}</td>
          </tr>

        </table>
        <div class="show-mobile">
          <div class="item">
            <p>{{ cms.page.fgPPraise }}</p>
            <p>
              {{ cms.page.fgAcknowledgeGodAsGreat }}
            </p>
          </div>
          <div class="item">
            <p>{{ cms.page.fgRRepent }}</p>
            <p>{{ cms.page.fgConfessAnyUnresolvedSin }}</p>
          </div>
          <div class="item">
            <p>{{ cms.page.fgAAsk }}</p>
            <p>
              {{ cms.page.fgAskForThoseThingsOnlyGod }}
            </p>
          </div>
          <div class="item">
            <p>{{ cms.page.fgYYield }}</p>
            <p>
              {{ cms.page.fgCommitYourWillToGodAndTrust }}
            </p>
          </div>
        </div>
      </div>
      <div class="international">
        <h3 class="subtitle">
          {{ cms.page.fgPrayingForTheGideons }}
        </h3>
        <p>
          {{ cms.page.fgMembersOfTheGideons }}
        </p>
        <ul class="list">
          <li>
            {{ cms.page.fgPrayForGodToOpenDoors }}
          </li>
          <li>
            {{ cms.page.fgPrayForTheProtection }}
          </li>
          <li>
            {{ cms.page.fgPrayForGideonsInYourLocal }}
          </li>
          <li>
            {{ cms.page.fgPrayThatGideonsWouldGain }}
          </li>
          <li>
            {{ cms.page.fgPrayThatYourWitnessWouldIncrease }}
          </li>
          <li>
            {{ cms.page.fgMostImportantly }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {mapStores} from "pinia";
import {useDesignStore} from "@/stores/designStore";
import {useCmsStore} from "@/stores/cmsStore";

export default {
  name: "Prayer",
  data() {
    return {
      cms: {
        loaded: false, // set to 'true' when CMS content has been returned from API
        options: {
          Common: true,
          Components: [],
          LanguageCode: "",
          PageName: "prayer_helps",
          MinistryUpdates: false,
        },
        page: {},
      },
    };
  },
  computed: {
    ...mapStores(useCmsStore, useDesignStore),
  },
  async created() {
    this.setContentLoaded(false);
    this.setTitlePageFromCms()
    await this.fetchCmsData();
  },
  methods: {
    async fetchCmsData() {
      try {
        await this.cmsStore
            .getPageAndOrCommonAndOrComponents(this.cms.options)
            .then((results) => {
              if (results) {
                this.cms.page = results;
              } else return false;
              this.setTitlePageFromCms(results)
              this.setContentLoaded(true);
              return true;
            });
      } catch (e) {
        console.error(e);
      }
    },
    setContentLoaded(loadedStatus) {
      this.cms.loaded = loadedStatus;
    },
    setTitlePageFromCms(page) {
      const pageTitle = page && page.fgPrayer ? page.fgPrayer : 'Prayer'
      const pageSubtitle = page && page.fgPrayWithUs ? page.fgPrayWithUs : 'Pray with us'

      this.designStore.setTitlePage({
        title: pageTitle,
        sub_title: pageSubtitle,
        img_class: "bg-friend",
        btn_history: false,
      });
    },
  },
};
</script>

<style scoped lang="scss">

.prayer-outer-wrapper {
  padding: 50px 15px;
  width: 1400px;
  margin: 0 auto;
  position: relative;
  max-width: 100%;

  .prayer {
    color: $blue-darker;

    .subtitle {
      margin: 0 0 20px;
      line-height: 1.5;
      @media screen and (max-width: $md) {
        font-size: 20px;
      }
    }

  }
}

.importance {
  margin: 0 0 40px;

  p {

  }

  .list {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    margin: 40px 0 35px;
    @media screen and (max-width: $md) {
      margin: 17px 0 35px;
    }

    &-item {
      background: white;
      border-left: 10px solid $blue-darker;
      box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
      padding: 40px 40px 23px;
      width: 450px;
      max-width: 50%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: space-between;
      @media screen and (max-width: $xl) {
        width: 33.33%;
      }
      @media screen and (max-width: $lg) {
        width: calc(50% - 25px);
        max-width: calc(50% - 25px);
      }
      @media screen and (max-width: $md) {
        width: 100%;
        max-width: 100%;
      }

      & + .list-item {
        margin-left: 50px;
        @media screen and (max-width: $md) {
          margin: 20px 0 0;
          min-height: 175px;
        }
      }

      &-title {
        font-size: 19px;
        margin: 0 0 30px;
        font-family: $baskerville, serif;
        margin-bottom: 40px;
        @include MQ(S) {
          font-size: 17px;
        }
      }

      &-text {
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 0;
        @include MQ(S) {
          font-size: 15px;
        }
      }
    }
  }
}

.approach {
  margin: 0 0 40px;

  table {
    margin: 25px 0;
    border-collapse: collapse;
    width: 100%;
    border: 1px solid rgba(black, .2);
    @include MQ(S) {
      display: none;
    }

    td,
    th {
      border-right: 1px solid rgba(black, .2);
      padding: 25px;
    }

    th {
      border-bottom: 1px solid rgba(black, .2);

      &:last-child {
        text-align: left;
      }
    }

    tr {
      td:not(:last-child) {
        text-align: center;
        font-weight: 500;
      }
    }
  }

  .show-mobile {
    display: none;
    @media screen and (max-width: $md) {
      display: flex;
      flex-wrap: wrap;
      margin: 20px 0 0;
    }

    .item {
      margin: 0 0 20px;

      &:last-child {
        margin: 0;
      }
    }

    p {
      &:first-child {
        font-weight: bold;
      }
    }
  }
}

.international {
  .list {
    margin: 15px 25px 0;

    li {
      margin: 0 0 10px;
      @media screen and (max-width: $md) {
        font-size: 15px;
      }
    }
  }
}

.text-center {
  text-align: center;
}
</style>